import { React, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import {Homepage, Howpage, Pricingpage, Faqpage, Contactpage, Footer, Chat, Index } from './App';
import { AnimatePresence } from "framer-motion";

function ScrollToTop({ children }) {
  const { pathname } = useLocation();
  
  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }

    window.scrollTo(0, 0);
  }, [pathname]);
  
  return children;
}
/*
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
*/
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <Router>
      <ScrollToTop/>
      <AnimatedRoutes />
      <Footer/>
    </Router>,
);


function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes key={location.pathname} location={location}>
      <Route exact path="/" element={<Index/>}/>
        <Route exact path="/what" element={<Homepage/>}/>
        <Route exact path="/how" element={<Howpage/> }/>
        <Route exact path="/pricing" element={<Pricingpage/> }/>
        <Route exact path="/faq" element={<Faqpage/> }/>
        <Route exact path="/contact" element={<Contactpage/> }/>
      </Routes>
    </AnimatePresence>
  );
}

export { AnimatedRoutes, ScrollToTop };


