import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import homeImg1 from './img/macbook.webp';
import promoApp from './img/multiplatform.png';
import logoLrg from './img/logolrg.png';
import bgVideo from './img/bgvid.mp4';
import bgVideo2 from './img/bgvid.webm';
import phone1 from './img/phone1.webp';
import phone2 from './img/phone2.webp';
import phone3 from './img/phone3.webp';
import tablet1 from './img/tablet1.webp';
import tablet2 from './img/tablet2.webp';
import partner3cx from './img/3cx.jpg';
import brandpepsi from './img/pepsi.png';
import brandmcdonalds from './img/mcdonalds.png';
import brandikea from './img/ikea.png';
import brandnhs from './img/nhs.png';
import brandamericanexpress from './img/americanexpress.png';
import termsAndConditions from './files/termsAndConditions.pdf';
import criticalInfoFlexi from './files/criticalInfoFlexi.pdf';
import criticalInfoYearly from './files/criticalInfoYearly.pdf';
import {Helmet} from "react-helmet";
import emailjs from '@emailjs/browser';
import Hamburger from 'hamburger-react';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Pulse from 'react-reveal/Pulse';

/*
function App() {
  return (
    <div className="App">
      
      
    </div>
  );
}

export default App;*/

var home = false;
var how = false;
var pricing = false;
var faq = false; 
var contact = false;
var index = false;

const variants = {
  hidden: { opacity: 0},
  enter: { opacity: 1},
  exit: { opacity: 0},
}

const Header = () => {
  

	return (
    
    
    <div className="header">
      {home ? <div className="headerBg purple"></div> : <div></div>}
      {how ? <div className="headerBg purple"></div> : <div></div>}
      {pricing ? <div className="headerBg purple"></div> : <div></div>}
      {faq ? <div className="headerBg purple"></div> : <div></div>}
      {contact ? <div className="headerBg purple"></div> : <div></div>}
      
      <div className="headerLinks">
        <Link to='/what' className="headerLink">What We Offer</Link>
        <Link to='/how' className="headerLink">How It Works</Link>
        <Link to='/pricing' className="headerLink">Pricing</Link>
        <Link to='/faq' className="headerLink">FAQ</Link>
        <Link to='/contact' className="headerLink">Get In Touch</Link>
      </div>
      
    </div>
    
	)
};

const Footer = () => {
	return (
    <div>

      <div className="footer">
        <div className="footerGrid">
          <div className="fg">
            <h3>Contact Information</h3>
            <p>
              <span>(07) 5241 5555</span><br/>
              <a href="mailto:contact@hellovoip.com.au">contact@hellovoip.com.au</a>
            </p>
            <img src={partner3cx} className="partner3cx" alt="3cx" />
          </div>
          <div className="fg">
            <h3>Legal</h3>
            <p>
            <a href={termsAndConditions} rel="noreferrer" target='_blank'>Terms and Conditions</a><br/>
            <a href={criticalInfoFlexi} rel="noreferrer" target='_blank'>Critical Information (Flexi)</a><br/>
            <a href={criticalInfoYearly} rel="noreferrer" target='_blank'>Critical Information (Yearly)</a><br/>
            </p>
          </div>
        </div>
      </div>

      {/*
      <a href="https://www.nkdtech.com.au" target='_blank' rel="noreferrer">
        <div className="footerLogo">
          
          <div className="nkdlogo" alt="nkdlogo"></div>
          <div className="nkdsubtext">POWERED BY</div>
        </div>
      </a>
  */}
    </div>
		
	)
};


const Chat = () => {

  

  return (
    <div>
      <call-us-selector 
        phonesystem-url="https://nkdtech.3cx.com.au" 
        party="LiveChat185641" 
        className="chat"
        minimized="true" 
        animation-style="noanimation" 
        minimized-style="bubbleright" 
        allow-call="false" 
        allow-video="false" 
        allow-soundnotifications="true" 
        enable-mute="true" 
        enable-onmobile="true" 
        offline-enabled="true" 
        enable="true" 
        ignore-queueownership="false" 
        authentication="both" 
        operator-name="Hellovoip Support" 
        show-operator-actual-name="false" 
        aknowledge-received="true" 
        gdpr-enabled="false" 
        message-userinfo-format="both" 
        message-dateformat="both" 
        lang="browser" 
        button-icon-type="default" 
        greeting-visibility="none" 
        greeting-offline-visibility="none" 
        chat-delay="2000" 
        enable-direct-call="false" 
        enable-ga="false" 
      >
      </call-us-selector>
    </div>
  )

};

const Links = () => {

  //const [isVisible, setIsVisible] = useState(true);
  const [opacity, setOpacity] = useState(0)
  const [visibility, setVisibility] = useState('hidden')

  const listenToScroll = () => {
    let heightToHideFrom = 235;
    const winScroll = document.body.scrollTop ||
        document.documentElement.scrollTop;
  
    if (winScroll > heightToHideFrom) {
      //isVisible &&      // to limit setting state only the first time
        //setIsVisible(true);
        setOpacity(1);
        setVisibility('visible');
    } else {
        //setIsVisible(false);
        setOpacity(0);
        setVisibility('hidden');
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () =>
      window.removeEventListener("scroll", listenToScroll);
  }, [])

  


  return (
    <div className="links">
        <div style={{'height':'10px'}}></div>
        {home 
          ? <Link to='/what' className="link p">What We Offer</Link>
          : <Link to='/what' className="headerLink">What We Offer</Link>
        }
        {how 
          ? <Link to='/how' className="link p">How It Works</Link>
          : <Link to='/how' className="headerLink">How It works</Link>
        }
        {pricing 
          ? <Link to='/pricing' className="link p">Pricing</Link>
          : <Link to='/pricing' className="headerLink">Pricing</Link>
        }
        {faq 
          ? <Link to='/faq' className="link p">FAQ</Link>
          : <Link to='/faq' className="headerLink">FAQ</Link>
        }
        {contact 
          ? <Link to='/contact' className="link p">Get In Touch</Link>
          : <Link to='/contact' className="headerLink">Get In Touch</Link>
        }
        <Link to='/'><div className="headerLogo" alt="headerLogo" style={{opacity: opacity, visibility: visibility}} ></div></Link>
        
        <hr/>
    </div>
  )
};

const MobileMenu = () => {

  const [opacity, setOpacity] = useState(0)
  const [visibility, setVisibility] = useState('hidden')

  return (
    <div className="mobile">
      <div className="mobileWrapper" style={{opacity: opacity, visibility: visibility}}>
        <div className="mobileInner">
          <Link to='/'><div className="mobileLogo" alt="mobileLogo"></div></Link>
          <div className="line"></div>
          <Link to='/what' className="mobileLink">What We Offer</Link><br/>
          <Link to='/how' className="mobileLink">How It Works</Link><br/>
          <Link to='/pricing' className="mobileLink">Pricing</Link><br/>
          <Link to='/faq' className="mobileLink">FAQ</Link><br/>
          <Link to='/contact' className="mobileLink">Get In Touch</Link><br/>
        </div>
      </div>

      {index 
        ? <div className="hamburger p">
            <Hamburger onToggle={toggled => {
              if (toggled) {
                setOpacity(1)
                setVisibility('visible');
              } else {
                setOpacity(0)
                setTimeout(() => {
                  setVisibility('hidden');
                }, 500);
              }
            }} />
          </div>
      : <div className="hamburger">
          <Hamburger onToggle={toggled => {
            if (toggled) {
              setOpacity(1)
              setVisibility('visible');
            } else {
              setOpacity(0)
              setTimeout(() => {
                setVisibility('hidden');
              }, 500);
            }
          }} />
        </div>
      }
      

    </div>
  )

};

const Index = () => {
  home = false;
  how = false;
  pricing = false;
  faq = false; 
  contact = false;
  index = true;

  const [imageClicked, setImageClicked] = useState({
    call: true,
    message: false,
    meet: false,
  });

  const [imageClicked2, setImageClicked2] = useState({
    video: true,
    message: false,
  });

  const onClickHandler = (order) => {
    const resetImages = {
      call: false,
      message: false,
      meet: false,
    }
    setImageClicked({
      ...resetImages,
      [order]: true
    });
  };

  const onClickHandler2 = (order) => {
    const resetImages = {
      video: false,
      chat: false,
    }
    setImageClicked2({
      ...resetImages,
      [order]: true
    });
  };

  return (
    <motion.div
      variants={variants} // Pass the variant object into Framer Motion 
      initial="hidden" // Set the initial state to variants.hidden
      animate="enter" // Animated state to variants.enter
      exit="exit" // Exit state (used later) to variants.exit
      transition={{ type: 'linear' }} // Set the transition to linear
      className=""
    >
      <Helmet>
        <title>hellovoip 3CX communication by nkdtech</title>
        <meta name="theme-color" content="#816EDC"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="#816EDC"/>
        <meta name="msapplication-TileColor" content="#816EDC"/>
        <script defer src="https://downloads-global.3cx.com/downloads/livechatandtalk/v1/callus.js" id="tcx-callus-js" charset="utf-8"></script>`
        
      </Helmet>

      <div className='mainContent'>
        <div className='screen s1'>
          <Pulse>
            <img src={logoLrg} className="logoLrg" alt="logolrg" />
          </Pulse>

          <video id="background-video" autoPlay loop muted playsinline="true">
              <source src={bgVideo} type="video/mp4"/>
              <source src={bgVideo2} type="video/webm"/>
              Your browser does not support the video tag.
          </video>

          <div className="mouseContainer">
            <div className="mouse"></div>
          </div>
          
        </div>

        <div className='screen s2'>
          <div className='contentDrk'>
            <Fade bottom>
              <h1>Your Communication Solution</h1>
              <h3>We provide the best all in one tool for your business communication needs.</h3>
            </Fade>
            <div className="promosContainer">
              <Fade left>
                <div className="row">
                  <div className='rowInner'>
                    <h4>Communication Reinvented</h4>
                    <p>
                      <span onMouseOver={() => onClickHandler("call")}>Call</span>, <span  onMouseOver={() => onClickHandler("message")}>Message</span>, <span onMouseOver={() => onClickHandler("meet")}>Meet</span> on the Go.
                    </p>
                    <p className='p2'>
                      Link WhatsApp, Facebook, SMS, Website Chat to a single application.
                      <br/><br/>
                      Receive Voicemails by email, transfer calls to other employees.
                      <br/><br/>
                      Create Conference calls with internal employees.
                    </p>

                    <Link to='/how'><button className="indexBtn">View How it Works</button></Link>
                  </div>
                </div>
              </Fade>
              <Fade right>
                <div className="row">
                  <div className="image">
                    { imageClicked.call && <img src={phone1} alt="call" className="phone"/> }
                    { imageClicked.message && <img src={phone2} alt="message" className="phone"/> }
                    { imageClicked.meet && <img src={phone3} alt="meet" className="phone"/> }
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>

        <div className = 'screen s2b'>
        </div>

        <div className = 'screen s3'>

          <div style={{'height':'50px'}}></div>

          <div className='contentDrk'>
            <div className="promosContainerb">
              <div className="rowb row2">
                <Fade left>
                  <div>
                    <div className="image">
                      { imageClicked2.video && <img src={tablet1} alt="video" className="tablet"/> }
                      { imageClicked2.chat && <img src={tablet2} alt="chat" className="tablet"/> }
                    </div>
                  </div>
                </Fade>
              </div>
              <div className='rowb row3'>
                <Fade right>
                  <div className='rowInner'>
                    <h4>Everything, Anywhere</h4>
                    <p>
                      <span onMouseOver={() => onClickHandler2("video")}>Voice Conferencing</span>, <span onMouseOver={() => onClickHandler2("chat")}>Team Management</span>, It's all here.
                    </p>
                    <p className='p2'>
                        Hassle-free video conferencing.
                        <br/><br/>
                        Team Sharing & Collaboration with Document Sharing, Chats, Polls, Presentation and Remote assistance tools.
                        <br/><br/>
                        Control and Manage your communication for teams and customers.
                        <br/><br/>
                        Usable from your web browser, or apps available on Android and iOS.
                    </p>

                    <Link to='/pricing'><button className="indexBtn b">View our Prices</button></Link>
                  </div>
                </Fade>
              </div>
            </div>
          </div>

          <div style={{'height':'60px'}}></div>
        </div>

        <div className = 'screen s3b'>
        </div>

        <div className = 'screen s4'>
          <div className='contentDrk'>
            <Fade bottom>
              <h1>Industry Leading Software</h1>
              <h3>3CX is used by some of the worlds largest companies!</h3>
            </Fade>

            <Fade bottom>
              <div className="brandContainer">
                <div className="bRow">
                  <img src={brandpepsi} className="brandLogo" alt="brandpepsi" />
                </div>
                
                <div className="bRow">
                  <img src={brandmcdonalds} className="brandLogo" alt="brandmcdonalds" />
                </div>

                <div className="bRow">
                  <img src={brandikea} className="brandLogo" alt="brandikea" />
                </div>

                <div className="bRow">
                  <img src={brandamericanexpress} className="brandLogo" alt="brandamericanexpress" />
                </div>

                <div className="bRow">
                  <img src={brandnhs} className="brandLogo" alt="brandnhs" />
                </div>
              </div>
            </Fade>
          </div>
        </div>

        <div className = 'screen sLast'>

          <div style={{'height':'60px'}}></div>
          <div className="contentDrk">
            <Fade bottom>
              <h1 style={{'textAlign':'center'}}>We are here for you!</h1>
              <h3 style={{'textAlign':'center'}}><Link to='/contact' className="h3Link">Get In Touch</Link> and talk to our communication specialists.</h3>
            </Fade>
          </div>
          <div style={{'height':'60px'}}></div>
        </div>
        
        <MobileMenu/>
      </div>

    </motion.div>
  )

};

const Homepage = () => {
  home = true;
  how = false;
  pricing = false;
  faq = false; 
  contact = false;
  index = false;

	return (
    <motion.div
      variants={variants} // Pass the variant object into Framer Motion 
      initial="hidden" // Set the initial state to variants.hidden
      animate="enter" // Animated state to variants.enter
      exit="exit" // Exit state (used later) to variants.exit
      transition={{ type: 'linear' }} // Set the transition to linear
      className=""
    >
      <Helmet>
        <title>hellovoip 3CX communication by nkdtech | What We Offer</title>
        <meta name="theme-color" content="#816EDC"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="#816EDC"/>
        <meta name="msapplication-TileColor" content="#816EDC"/>
        <script defer src="https://downloads-global.3cx.com/downloads/livechatandtalk/v1/callus.js" id="tcx-callus-js" charset="utf-8"></script>
      </Helmet>
      <div>
        <div className="borderTop green"></div>
        <div className="content">
          <div className="logo logoG" alt="logo"></div>
          <div className="subTitle">
            Communication streamlined to work for you.
          </div>
          <Links/>
          <MobileMenu/>
          <div className="innerContent">
            <div className='box'>
              <div className="l">
                <h3>What is hellovoip?</h3>
              </div>
              <div className="r">
                <p>
                  hellovoip is an affordable communication service that scales with your needs, not just relying on simple phone calls but giving your business access to live chat, SMS and video conferencing with employees and customers all in one place.  
                  <br/><br/>
                  Our goal is to provide the best communication tools available and so we are proud to offer 3CX, an award winning business unified software solution, to you at extremely competitive prices.
                </p>
              </div>
            </div>

            <img src={homeImg1} className="promoImg1" alt="homeImg1" />

            <div>
              <div className="line"></div>
              <h4>Think of hellovoip as your virtual receptionist, where we combine multiple platforms into one simple to use application.</h4>
              <div className="line"></div>
            </div>
            
            <div className='box'>
              <div className="l">
                <h3>Why use hellovoip'?</h3>
              </div> 
              <div className="r">
                <p>
                  hellovoip adds a level of professionalism to your business with secure and reliable service. It is your one stop solution to your communication needs.
                  <br/><br/>
                  
                </p>
                <div style={{'height':'20px'}}></div>
                <ul>
                  <li>Keep your existing phone numbers or easily create new ones</li>
                  <li>Make and receive calls within 3CX app on your mobile</li>
                  <li>Customised voicemails</li>
                  <li>Copy of received voicemails immediately in your email -- never lose them!</li>
                  <li>Customised keypad prompts</li>
                  <li>Customised music/on-hold</li>
                  <li>Separate business from personal</li>
                  <li>On the go, hellovoip moves with you</li>
                  <li>Set operating hours for your business</li>
                  <li>Filter other platforms into one (Facebook, SMS, Web Chat)</li>
                  <li>Call forwarding/diversions for always on the go</li>
                  <li>Data reports on phone activity</li>
                  <li>Android, Apple and Windows compatible, a multiplatform solution</li>
                  <li>Website chat window syncs to app</li>
                  <li>Scalability: add extensions, phones and so much more!</li>
                </ul>
              </div>
            </div>

            <div className="line"></div>
            <div style={{'height':'60px'}}></div>
            <h3>Don't Settle for Less</h3><br/>
            <p>
            Transform the way you communicate and take your business to new heights with the 3CX phone system. Ditch the outdated approach of relying solely on phone calls, and embrace a modern, versatile approach that incorporates Live Chat, Facebook, business SMS, and Video Conferencing – all from a single client, eliminating the need to switch between different apps.
            <br/><br/>
            Whether you prefer to work from your web browser, desktop, or mobile device, 3CX has you covered with intuitive clients for every platform. If you prefer the traditional feel of an IP phone, 3CX can easily connect with supported models, giving you the flexibility to choose how you communicate.
            <br/><br/>
            Don't settle for less – make the change to 3CX and experience many compelling reasons why it's the right choice for your business
            </p>

          </div>
        </div>
        <div style={{'height':'180px'}}></div>
      </div>
    </motion.div>
    
	)
};

const Howpage = () => {
  home = false;
  how = true;
  pricing = false;
  faq = false; 
  contact = false;
  index = false;

	return (
    <motion.div
      variants={variants} // Pass the variant object into Framer Motion 
      initial="hidden" // Set the initial state to variants.hidden
      animate="enter" // Animated state to variants.enter
      exit="exit" // Exit state (used later) to variants.exit
      transition={{ type: 'linear' }} // Set the transition to linear
      className=""
    >
      <Helmet>
        <title>hellovoip 3CX communication by nkdtech | How It Worksr</title>
        <meta name="theme-color" content="#816EDC"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="#816EDC"/>
        <meta name="msapplication-TileColor" content="#816EDC"/>
        <script defer src="https://downloads-global.3cx.com/downloads/livechatandtalk/v1/callus.js" id="tcx-callus-js" charset="utf-8"></script>`
      </Helmet>
      
      <div className="borderTop red"></div>
      <div>
          <div className="content">
            <div className="logo logoG" alt="logo"></div>
            <div className="subTitle">
              Communication streamlined to work for you.
            </div>
            <Links/>
            <MobileMenu/>
            <div className="innerContent">
              <div className='box'>
                <div className="l">
                  <h3 className="subHeadings">We make it easy</h3>
                </div>
                <div className="r">
                  <p>
                    Simply provide us with the details required to port your existing number to our services, or we can provide you with a new number. Once connected we can provide you with a landline VoIP device or you can download the 3CX app on to your mobile device and use your provided login.
                  </p>
                </div>
              </div>
              <div className="line"></div>
              <div style={{'height':'60px'}}></div>
              <h3>Implementation Process</h3><br/>
            </div>

            

            <div className="innerContent">
              <div className="blocks">
                <div className="block one">
                  <div>
                    <h3>Step 1 - Onboarding</h3>
                    <p>
                    <b>Process</b>
                      
                    </p>
                    <ul>
                      <li>Get in Touch</li>
                      <li>We will match you with a suitable plan</li>
                      <li>Provide us with any required documentation (we will let you know)</li>
                      <li>We can port an existing number</li>
                      <li>New services also available</li>
                    </ul>
                  </div>
                </div>

                <div className="block two">
                  <div>
                    <h3>Step 2 - Setup</h3>

                    <p>
                      <b>Your Part</b>
                    </p>
                    <ul>
                        <li>Prepare your custom requests for your digital receptionist</li>
                        <li>Setup business operating times</li>
                    </ul>

                    <p>
                      <b>Our Part</b>
                    </p>
                    <ul>
                        <li>We will setup and manage your service</li>
                        <li>We provide the best security and reliability</li>
                    </ul>
                    

                  </div>
                </div>

                <div className="block three">
                  <div>
                    <h3>Step 3 - Get Started</h3>
                    <p>
                      <b>Using your Service</b>
                      
                    </p>
                    <ul>
                      <li>Learn how to use your service</li>
                      <li>Download the 3CX application on your mobile (iOS, Android)</li>
                      <li>Login to the 3CX Web-portal to manage your account</li>
                      <li>Setup your desktop phone (if applicable)</li>
                    </ul>
                  </div>
                </div>  
              </div>
            </div>
            
            
            <div className='innerContent'>
              <div style={{'height':'60px'}}></div>
              <div className="line"></div>
              <h4>Hellovoip works seemlessly for you</h4>
              <div className="line"></div>
              <div style={{'height':'60px'}}></div>

              <div>
                <h3>3CX Applications</h3><br/>
                <p>
                  3CX is multi-platform, you have the ability to use your communication across Windows, Mac, iOS and Android.
                </p>
                <br/>
                <br/>
                <div className='colz'>
                  <div className='col2'>
                    <img src={promoApp} className="promoImg2" alt="homeImg2" />
                  </div>
                  <div className='col2'>
                    <ul>
                      <li>App-Free Video Conferencing, share screen and documents as well as chat and polling features.</li>
                      <li>Call, message, meet on-the-go.</li>
                      <li>Same office extension on Android & iOS apps.</li>
                      <li>Web Client accessible from anywhere.</li>
                      <li>Chat with your customers.</li>
                      <li>Easy Management with hellovoip customer support.</li>
                    </ul>
                  </div>
                </div>

                <div style={{'height':'60px'}}></div>
                <div className="line"></div>

              </div>
            </div>  
            
              
            
            <div className='innerContent'>
              <div className='box'>
                <div className="l">
                  <h3>Popular Features</h3><br/>
                  <p>
                      These are just some of the features we can provide with 3CX Technology.
                  </p>
                  <br/><br/><br/>
                </div>
                <div className="r">
                  <div className='box3'>
                    <div className='col'>
                      <h5>CRM</h5>
                      <p>3CX works with many popular CRM Software, with the ability to launch and log calls</p>
                    </div>
                    <div className='col'>
                      <h5>Office 365</h5>
                      <p>Sync Outlook Calendars and Contacts with 3CX</p>
                    </div>
                    <div className='col'>
                      <h5>Mobile App</h5>
                      <p>Take 3CX everywhere using the Android and iOS 3CX Applications so you never miss a call</p>
                    </div>
                  </div>
                  <div className='box3'>
                    <div className='col'>
                      <h5>Queue Calls</h5>
                      <p>Answer phone calls in the order they arrive</p>
                    </div>
                    <div className='col'>
                      <h5>Message Bank</h5>
                      <p>Control hours that you are accepting calls with the ability for callers to leave voicemail</p>
                    </div>
                    <div className='col'>
                      <h5>Video Chat</h5>
                      <p>Integrated Video Chat for all your conferencing needs.</p>
                    </div>
                  </div>
                  <div className='box3'>
                    <div className='col'>
                      <h5>Virtual Receptionist</h5>
                      <p>Greet calls with a voice operated menu</p>
                    </div>
                    <div className='col'>
                      <h5>Record Calls</h5>
                      <p>Ability to record any calls</p>
                    </div>
                    <div className='col'>
                      <h5>Voicemail</h5>
                      <p>Ability to have voicemails sent to your email </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        <div style={{'height':'250px'}}></div>
      </div>
    </motion.div>
	)
};

const Pricingpage = () => {
  home = false;
  how = false;
  pricing = true;
  faq = false; 
  contact = false;
  index = false;

  const [checked, setChecked] = useState(false);
  const toggleChecked = () => {
    setChecked(value => !value);
  }

	return (
    <motion.div
      variants={variants} // Pass the variant object into Framer Motion 
      initial="hidden" // Set the initial state to variants.hidden
      animate="enter" // Animated state to variants.enter
      exit="exit" // Exit state (used later) to variants.exit
      transition={{ type: 'linear' }} // Set the transition to linear
      className=""
    >
      <Helmet>
        <title>hellovoip 3CX communication by nkdtech | Pricing</title>
        <meta name="theme-color" content="#816EDC"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="#816EDC"/>
        <meta name="msapplication-TileColor" content="#816EDC"/>
        <script defer src="https://downloads-global.3cx.com/downloads/livechatandtalk/v1/callus.js" id="tcx-callus-js" charset="utf-8"></script>`
      </Helmet>
      <div>
        <div className="borderTop yellow"></div>
        <div className="content">
          <div className="logo logoG" alt="logo"></div>
          <div className="subTitle">
            Communication streamlined to work for you.
          </div>
          <Links/>
          <MobileMenu/>
          <div className="innerContent">
            <div className='box'>
              <div className="l">
                <h3>Affordable and Competitive</h3>
              </div>
              <div className="r">
                <p>
                  'hellovoip' uses a competitive tiered pricing structure that is flexible to your needs.
              </p>
              </div>
            </div>

            <label>Select Contract Type:</label>
            <div className='switchBox'>
              <div className="switchBoxDiv">
                {checked
                  ? <p>12-Month Contract</p>
                  : <p className="active">12-Month Contract</p>
                }
              </div>
              <div className="switchBoxDiv">
                <label class="switch">
                  <input 
                    type="checkbox"
                    checked={checked}
                    onChange={toggleChecked}
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              <div className="switchBoxDiv">
              {checked
                  ? <p className="active">Flexi (No Lock-In Contract)</p>
                  : <p>Flexi (No Lock-In Contract)</p>
                }
              </div>
            </div>
            
            
          </div>

          <div style={{'height':'30px'}}></div>

          <div className="blocks">
            {checked
              ? <div className="block c">
                  <div>
                    <h3>Tier 1</h3>
                    
                    <p className="p3">
                      Ideal for a Small Business
                    </p>
                    
                    <p>
                      <span id="priceA">$209*</span>
                      <span className="span2"> / mo</span><br/>
                      <span className="span3">Per Month ex. GST</span>
                      <br/>
                      <b>4 Simultaneous Calls</b>
                      <hr/>
                      <span className="span4">Cloud Hosting</span><br/>
                      <span className="span4">1 DID (Phone Number)</span><br/>
                      <span className="span4">Unlimited Local, Mobile and National Calls</span><br/>
                      <span className="span4">Additional Numbers $10ea /m</span><br/>
                      <hr/>
                      <p className="i2">
                        *No Lock-in Contract<br/>
                        Set Up Fee $349 inc GST<br/>
                        Optional SMS Features $250 (Billed Annually)
                      </p>
                    </p>
                    
                  </div>
                </div>

              : <div className="block a">
                  <div>
                    <h3>Tier 1</h3>
                    <div className="popular"><p>Popular</p></div>
                    
                    <p className="p3">
                      Ideal for a Small Business
                    </p>
                    
                    <p>
                      <span id="priceA">$169*</span>
                      <span className="span2"> / mo</span><br/>
                      <span className="span3">Per Month ex. GST</span>
                      <br/>
                      <b>4 Simultaneous Calls</b>
                      <hr/>
                      <span className="span4">Cloud Hosting</span><br/>
                      <span className="span4">1 DID (Phone Number)</span><br/>
                      <span className="span4">Unlimited Local, Mobile and National Calls</span><br/>
                      <span className="span4">Additional Numbers $10ea /m</span><br/>
                      <hr/>
                      <p className="i2">
                        <i>*12-Month Contract<br/></i>
                        Set Up Fee $349<br/>
                        Optional SMS Features $250 (Billed Annually)
                      </p>
                    </p>
                    
                  </div>
                </div>

            }

            
            <div className="block b">
              <div>
                <h3>Tier 2</h3>
                <p className="p3">
                  Ideal for a Medium-Sized Business
                </p>

                <p>
                  {checked
                    ? <span id="priceA">$359*</span>
                    : <span id="priceA">$299*</span>
                  } 
                  <span className="span2"> / mo</span><br/>
                  <span className="span3">Per Month ex. GST</span>

                  <br/>
                  <b>8 Simultaneous Calls</b>
                  
                  <hr/>
                  <span className="span4">Cloud Hosting</span><br/>
                  <span className="span4">2 DID (Phone Numbers)</span><br/>
                  <span className="span4">Unlimited Local, Mobile and National Calls</span><br/>
                  <span className="span4">Additional Numbers $10ea /m</span><br/>
                  <hr/>

                  <p className="i2">
                    {checked
                      ? <i>*No Lock-in Contract<br/></i>
                      : <i>*12-Month Contract<br/></i>
                    }
                    Set Up Fee $349<br/>
                    {checked
                      ? <i>Optional SMS Features $250 (Billed Annually)</i>
                      : <i>Optional SMS Features $250 (Billed Annually)</i>
                    }
                  </p>
                </p>
                
              </div>
            </div>

            <div className="block c">
              <div>
                <h3>Tier 3</h3>
                <p className="p3">
                  Ideal for a Large Business
                </p>

                <p>
                  {checked
                    ? <span id="priceA">$749*</span>
                    : <span id="priceA">$600*</span>
                  } 
                  <span className="span2"> / mo</span><br/>
                  <span className="span3">Per Month ex. GST</span>

                  <br/>
                  <b>16 Simultaneous Calls</b>
                  
                  <hr/>
                  <span className="span4">Cloud Hosting</span><br/>
                  <span className="span4">2 DID (Phone Numbers)</span><br/>
                  <span className="span4">Unlimited Local, Mobile and National Calls</span><br/>
                  <span className="span4">Additional Numbers $10ea /m</span><br/>
                  <hr/>

                  <p className="i2">
                    {checked
                      ? <i>*No Lock-in Contract<br/></i>
                      : <i>*12-Month Contract<br/></i>
                    }
                    Set Up Fee $349<br/>
                    {checked
                      ? <i>Optional SMS Features $250 (Billed Annually)</i>
                      : <i>Optional SMS Features $250 (Billed Annually)</i>
                    }
                  </p>
                </p>
                
              </div>
            </div>  
          </div>

          <div className="innerContent">

            <div style={{'height':'120px'}}></div>

            <div className="line"></div>
            <h4>We also offer a FLEXI No Lock-In Contract Plan from $209 /mo</h4>
            <div className="line"></div>

          
            <div className='box'>
              <div className="l">
                <h3>What plan is right for you</h3>
              </div>
              <div className="r">
                <p>
                  We also offer custom packages to fit your needs.<br/>
                  Feel free to <Link to='/contact'>contact us</Link> and discuss which plan we think is suitable for you!
                </p>
              </div>
            </div>
          </div>

          <h1>Join Us Today!</h1>

        </div>

        <div style={{'height':'250px'}}></div>
      </div>
    </motion.div>
    
	)
};

const Faqpage = () => {
  home = false;
  how = false;
  pricing = false;
  faq = true; 
  contact = false;
  index = false;

	return (
    <motion.div
      variants={variants} // Pass the variant object into Framer Motion 
      initial="hidden" // Set the initial state to variants.hidden
      animate="enter" // Animated state to variants.enter
      exit="exit" // Exit state (used later) to variants.exit
      transition={{ type: 'linear' }} // Set the transition to linear
      className=""
    >
      <Helmet>
        <title>hellovoip 3CX communication by nkdtech | FAQ</title>
        <meta name="theme-color" content="#816EDC"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="#816EDC"/>
        <meta name="msapplication-TileColor" content="#816EDC"/>
        <script defer src="https://downloads-global.3cx.com/downloads/livechatandtalk/v1/callus.js" id="tcx-callus-js" charset="utf-8"></script>`
      </Helmet>
      <div>
          <div className="borderTop purple"></div>
          <div className="content">
            <div className="logo logoG" alt="logo"></div>
            <div className="subTitle">
              Communication streamlined to work for you.
            </div>
            <Links/>
            <MobileMenu/>
            <div className="innerContent">
              <div className='box'>
                <div className="l">
                  <h3>Can I take my number with me?</h3>
                </div>
                <div className="r">
                  <p>Generally yes, and we can always check if this option is available to you prior to joining us.</p>
                </div>
              </div>

              <div style={{'height':'50px'}}></div>
              <div className="line"></div>
              <div className='box'>
                <div className="l">
                  <h3>How long does it take to setup?</h3>
                </div>
                <div className="r">
                  <p>Once you have completed all required documentation, we will have you setup within 14 working days.</p>
                </div>
              </div>

              <div style={{'height':'50px'}}></div>
              <div className="line"></div>
              <div className='box'>
                <div className="l">
                  <h3>Can I cancel my plan?</h3>
                </div>
                <div className="r">
                  <p>Yes, if you are outside your contract you can cancel anytime, If you're within your contract period there is a cancellation fee.</p>
                </div>
              </div>

              <div style={{'height':'50px'}}></div>
              <div className="line"></div>
              <div className='box'>
                <div className="l">
                  <h3>Can I change my voice prompt</h3>
                </div>
                <div className="r">
                  <p>Absolutely, 'hellovoip' has a form that you can submit to change your voice prompt, or simply just contact us and request a change. You can change 3x a year for free.</p>
                </div>
              </div>
            </div>
          </div>
        <div style={{'height':'250px'}}></div>
      </div>
    </motion.div>
    
	)
};

const Contactpage = () => {
  home = false;
  how = false;
  pricing = false;
  faq = false; 
  contact = true;
  index = false;

  const [disabled, setDisabled] = useState(false);

  const form = useRef();
  const btn = useRef();


  const sendEmail = (e) => {
    e.preventDefault();

    setDisabled(true);

    emailjs.sendForm('service_lxznl53', 'template_l2upqhj', form.current, 'hmnU1inX9VxED98c0')
      .then((result) => {
          console.log(result.text);
          
      }, (error) => {
          console.log(error.text);
      }); 
  };

	return (
    <motion.div
      variants={variants} // Pass the variant object into Framer Motion 
      initial="hidden" // Set the initial state to variants.hidden
      animate="enter" // Animated state to variants.enter
      exit="exit" // Exit state (used later) to variants.exit
      transition={{ type: 'linear' }} // Set the transition to linear
      className=""
    >
      <Helmet>
        <title>hellovoip 3CX communication by nkdtech | Get In Touch</title>
        <meta name="theme-color" content="#816EDC"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="#816EDC"/>
        <script defer src="https://downloads-global.3cx.com/downloads/livechatandtalk/v1/callus.js" id="tcx-callus-js" charset="utf-8"></script>`
      </Helmet>
      <div>
          <div className="borderTop blue"></div>
          <div className="content">
            <div className="logo logoG" alt="logo"></div>
            <div className="subTitle">
              Communication streamlined to work for you.
            </div>
            <Links/>
            <MobileMenu/>
            <div className="innerContent">
              <div className='box'>
                <div className="l">
                  <h3>Email Us</h3>
                </div>
                <div className="r">
                  <p>
                    Please get in touch with us via email (<a href="mailto:contact@hellovoip.com.au">contact@hellovoip.com.au</a>). We are available weekdays 9am - 5pm excluding public holidays. Feel free to use the form below to contact us!
                  </p>
                  
                </div>
              </div>

              <div className="line"></div>
              
              <div className="container mt-5">
                <form ref={form} onSubmit={sendEmail}>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="name">
                      Name
                    </label>
                    <input className="form-control" type="text" name="user_name" required />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="email">
                      Email
                    </label>
                    <input className="form-control" type="email" name="user_email" required />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="message">
                      Message
                    </label>
                    <textarea className="form-control" id="message" required />
                  </div>
                  <button className="btn btn-danger" type="submit" name="message" ref={btn} disabled={disabled}>SEND
                  </button>
                </form>
              </div>
              
            </div>
          </div>
        <div style={{'height':'250px'}}></div>
      </div>
    </motion.div>
    
	)
};



export {Homepage, Howpage, Pricingpage, Faqpage, Contactpage, Header, Footer, Links, MobileMenu, Chat, Index } ;